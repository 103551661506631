<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Ações">
      <b-row class="my-1 text-right mt-2 d-flex align-items-center">
        <b-col sm="12" md="6" class="d-flex">
          <b-form-group class="flex-grow-1">
            <b-form-input type="text" v-model="search" placeholder="Buscar item"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" class="d-flex justify-content-end">
          <b-button class="mb-1" variant="primary" @click="$router.push('/novo-projeto')" block> Novo Projeto + </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <label for="category" class="font-weight-bold text-primary">Status:</label>
            <b-form-select
              v-model="statusOptionSelected"
              :options="statusOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um status"
              @change="getAll"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="`Projetos de: ${currentWorkspace.workspace_title}`">
      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="filter()"
        :fields="fields"
        :sort-desc.sync="sortDesc"
        :per-page="perPage"
        :current-page="0"
        show-empty
        class="p-1 w-100 table-details"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong>Não há dados para exibir</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(project_code)="{ item }">
          {{ item.project_code }} <br />
          <small :style="{ color: 'red' }">{{ item.is_archived ? '(Arquivado)' : '' }}</small>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <Actions
            @openUnDeleteModal="openUnDeleteModal(item)"
            @openDeleteModal="openDeleteModal(item)"
            @openDeleteProjectModal="openDeleteProjectModal(item)"
            :item="item"
          />
        </template>
        <template v-slot:cell(finish_date)="{ item }">
          {{ item.finish_date ? dateFormatter(item.finish_date, 'dd/MM/yyyy') : 'indefinido' }}
        </template>
        <template v-slot:cell(start_date)="{ item }">
          {{ item.start_date ? dateFormatter(item.start_date, 'dd/MM/yyyy') : 'indefinido' }}
        </template>
        <template v-slot:cell(contract_date)="{ item }">
          {{ item.contract_date ? dateFormatter(item.contract_date, 'dd/MM/yyyy') : 'indefinido' }}
        </template>
      </b-table>
      <b-pagination
        class="justify-content-center"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="getPage"
        aria-controls="my-table"
      ></b-pagination>
      <div class="mr-2 d-flex justify-content-end">
      <strong>Total de projetos: {{ totalRows }} </strong>
    </div>
    </b-card>
    <b-modal v-model="deleteModal" title="Arquivar Projeto" hide-header-close hide-footer>
      <DeleteModal @closeDeleteModal="closeDeleteModal" :item="selectedItem" :exclusionType="exclusionType" />
    </b-modal>
    <b-modal v-model="unDeleteModal" title="Desarquivar Projeto" hide-header-close hide-footer>
      <UnDeleteModal @closeUnDeleteModal="closeUnDeleteModal" :item="selectedItem" />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BRow,
  BButton,
  BTable,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BAlert,
  BPagination
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';

import DeleteModal from './components/DeleteModal.vue';
import Actions from './components/ActionsProjects.vue';

import UnDeleteModal from './components/UnDeleteModal.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    Actions,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    DeleteModal,
    UnDeleteModal,
    BPagination,
    BAlert
  },
  data: () => ({
    search: '',
    exclusionType: '',
    sortDesc: false,
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false
      },
      {
        key: 'project_code',
        label: 'Código',
        sortable: true
      },
      {
        key: 'project_name',
        label: 'Nome do projeto',
        sortable: true
      },
      {
        key: 'client_name',
        label: 'Cliente',
        sortable: true
      },
      {
        key: 'contact_name',
        label: 'Contato',
        sortable: true
      },
      {
        key: 'finish_date',
        label: 'Prazo',
        sortable: true
      },
      {
        key: 'contract_date',
        label: 'Data do contrato',
        sortable: false
      },
      {
        key: 'start_date',
        label: 'Dia de início',
        sortable: false
      }
    ],
    deleteModal: false,
    unDeleteModal: false,
    items: [],
    selectedItem: null,
    showArchived: false,
    perPage: 20,
    currentPage: 1,
    totalRows: 0,
    statusOptions: [
      {
        value: 'todos',
        name: 'Todos'
      },
      {
        value: false,
        name: 'Em execução'
      },
      {
        value: true,
        name: 'Arquivado'
      }
    ],
    statusOptionSelected: false
  }),

  created() {
    this.getAll();
    console.log(this.currentWorkspace);
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    }
  },

  methods: {
    async getPage(val) {
      this.currentPage = val;
      await this.getAll();
    },
    async getFilter() {
      this.items = [];
      let body = {
        id: this.$route.params.id,
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.statusOptionSelected,
        page: 1,
        perPage: this.perPage,
        
      };
      this.$store
        .dispatch('_getProjects', body)
        .then(resp => {
          this.items = resp && resp.data ? this.orderByName(resp.data) : [];
          this.totalRows = resp && resp.pagination && resp.pagination.total ? resp.pagination.total : 0;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getAll() {
      let body = {
        id: this.$route.params.id,
        page: this.currentPage,
        perPage: this.perPage,
        archived: this.statusOptionSelected,
        workspace_id: this.$store.getters.currentWorkspace.id
      };
      this.$store
        .dispatch('_getProjects', body)
        .then(resp => {
          this.totalRows = resp && resp.pagination && resp.pagination.total ? resp.pagination.total : 0;
          this.items = resp && resp.data ? this.orderByName(resp.data) : [];
        })
        .catch(err => {
          console.log(err);
        });
    },

    closeDeleteModal() {
      this.deleteModal = false;
      this.getAll();
    },

    closeUnDeleteModal() {
      this.unDeleteModal = false;
      this.getAll();
    },

    openUnDeleteModal(item) {
      this.selectedItem = item;
      this.unDeleteModal = true;
    },

    openDeleteModal(item) {
      this.selectedItem = item;
      this.exclusionType = 'arquivar';
      this.deleteModal = true;
    },

    openDeleteProjectModal(item) {
      this.selectedItem = item;
      this.exclusionType = 'excluir';
      this.deleteModal = true;
    },

    filter() {
      if (this.search.trim() === '') {
        return this.items;
      } else {
        return this.items.filter(item => {
          return (
            (item.project_code && item.project_code.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.project_name && item.project_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.client_name && item.client_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.contact_name && item.contact_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          );
        });
      }
    },

    orderByName(array) {
      return array.sort((a, b) => {
        const nomeA = a.project_name.toUpperCase();
        const nomeB = b.project_name.toUpperCase();
        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }
        return 0;
      });
    },

    cleanFilter() {
      this.search = '';
    }
  }
};
</script>

<style>
.overflow-scroll-mobile {
  overflow-x: auto;
  white-space: nowrap;
}
.table-details {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
import { filter } from 'core-js/core/array';
